import clsx from 'clsx';
import {
  ElementType,
  ForwardedRef,
  forwardRef,
  ReactNode,
  RefAttributes,
} from 'react';
import { useFormState } from 'react-hook-form';

import { Button, ButtonProps } from '../../elements/buttons/Button';

export type SubmitProps<T extends ElementType = 'button'> = Omit<
  ButtonProps<T>,
  'type'
>;

export const SubmitButtonRHF: SubmitButtonRHF = forwardRef(
  function SubmitButtonRHF<T extends ElementType = 'button'>(
    props: SubmitProps<T>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) {
    const { disabled, className, as, ...otherProps } = props;
    const { isSubmitting } = useFormState();

    return (
      <Button
        disabled={isSubmitting || disabled}
        className={clsx('sm:self-start', className)}
        {...otherProps}
        as={as as ElementType}
        // after otherProps spread because when into a `<Dialog.Close asChild>`
        // props `type: 'button'` is set by Radix.
        type="submit"
        ref={ref}
      />
    );
  },
);
Object.assign(SubmitButtonRHF, { displayName: 'SubmitButtonRHF' });

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SubmitButtonRHF = <T extends ElementType = 'button'>(
  props: SubmitProps<T> & RefAttributes<HTMLButtonElement>,
) => ReactNode;
